import React, { useCallback, useState } from 'react';
import { Button, Grid, GridColumn, GridRow, Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import * as API from '../../../api'
import { useGetQueryByParams } from '../../../hooks/useGetQueryByParams';
import Loading from '../../common/Loading';
import FlexBox from '../../common/flexBox';
import SemanticFeatherIcon from '../../icons/SemanticFeatherIcon';
import { Edit, Plus, Trash2 } from 'react-feather';
import { useDeleteExternalUser } from './useDeleteExternalUser';

export interface IExternalCompetenceUserFormProps {
    onCancel: () => void
    refresh: () => void
}

interface IUserPositionListModalState {
    new: boolean
    edit: boolean
}

export type UserPositionType = "new" | "edit"

const ExternalCompetenceUserForm = ({ onCancel, refresh }: IExternalCompetenceUserFormProps) => {
    const { t } = useTranslation();

    const { isDestroying, onDestroy } = useDeleteExternalUser(refresh)
    const [modalState, setModalState] = useState<IUserPositionListModalState>({
        new: false,
        edit: false,
    });
    const {
        isLoading,
        data,
    } = useGetQueryByParams(true, API.skills.getVirtualUsers, "virtualUsers")

    const handleShowModal = useCallback((modal: UserPositionType) => {
        setModalState({ ...modalState, [modal]: true })
    }, [modalState])

    const handleOnCancel = (modal: UserPositionType) => {
        setModalState({ ...modalState, [modal]: false })
    }

    if (isLoading || !data) {
        return <Loading />
    }

    return (
        <Grid stackable>
            <GridRow columns="1">
                <GridColumn>
                    <Table basic="very">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell colSpan="7" textAlign="right">
                                    <Button
                                        icon
                                        labelPosition="left"
                                        primary
                                        type="button"
                                        onClick={() => handleShowModal("new")}
                                    >
                                        <SemanticFeatherIcon
                                            FeatherIcon={Plus}
                                            size={'60%'}
                                            centerIcon={true}
                                        />{' '}
                                        {t('new')}
                                    </Button>
                                </Table.HeaderCell>
                            </Table.Row>
                            <Table.Row>
                                <Table.HeaderCell>{t('name')}</Table.HeaderCell>
                                <Table.HeaderCell collapsing />
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {data?.map((x, i) => (
                                <Table.Row key={x.id}>
                                    <Table.Cell verticalAlign="top">
                                        {x.name}
                                    </Table.Cell>
                                    <Table.Cell verticalAlign="top" collapsing>
                                        <FlexBox justifyContent="end">
                                            <Button
                                                icon={<SemanticFeatherIcon FeatherIcon={Edit} />}
                                                onClick={() => handleShowModal("edit")}
                                                type="button"
                                                disabled={isDestroying}
                                            />
                                            <Button
                                                icon={<SemanticFeatherIcon FeatherIcon={Trash2} color='white' />}
                                                onClick={
                                                    () => onDestroy({
                                                        confirmText: t('confirm.deleteValue', { value: x.name }),
                                                        userId: x.id,
                                                    })
                                                }
                                                color='red'
                                                disabled={isDestroying}
                                                type="button"
                                            />
                                        </FlexBox>
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                </GridColumn>
            </GridRow>

        </Grid>
    );
};

export default ExternalCompetenceUserForm;