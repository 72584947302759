import React, { useCallback, useMemo, useState } from 'react'
import { Button, Checkbox, Message } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import {
    ColumnFiltersState, ExpandedState, PaginationState, SortingState, VisibilityState, createColumnHelper,
    getCoreRowModel, getExpandedRowModel, getFacetedRowModel, getFacetedUniqueValues, getFilteredRowModel,
    getPaginationRowModel, getSortedRowModel, useReactTable
} from '@tanstack/react-table'
import SemanticFeatherIcon from '../../../../icons/SemanticFeatherIcon'
import { ChevronDown, ChevronRight, Edit, GitBranch, Trash2, User } from 'react-feather'
import FullHeightStackingContainer from '../../../../common/fullHeightStackingContainer'
import TableReact, { countRowsWithSubRows, FooterLabel } from '../../../../wrappers/TableReact'
import useTableStorageBackedState from '../../../../wrappers/TableReact/hooks/useTableStorageBackedState'
import { ISkillsListViewDto } from '../../../../../api/skills'
import { useGetQueryByParams } from '../../../../../hooks/useGetQueryByParams'
import * as API from '../../../../../api'
import Loading from '../../../../common/Loading'
import ModalWrapper from '../../../../wrappers/ModalWrapper'
import ConnectPositionForm from '../../../../forms/ConnectPositionForm'
import { useDeleteSkillPositionConnect } from './useDeleteSkillPositionConnect'
import { useDeleteSkill } from './useDeleteSkill'
import SkillForm from '../../../../forms/SkillForm'
interface ISkillListModalState {
    new: boolean
    edit: boolean
    connect: boolean

}

export type SkillModalType = "new" | "edit" | "connect"

const SkillList = () => {
    const { t } = useTranslation()
    const {
        isLoading,
        data,
        refresh
    } = useGetQueryByParams(true, API.skills.getSkillsListView, "skillList")

    const [modalState, setModalState] = useState<ISkillListModalState>({
        new: false,
        edit: false,
        connect: false
    });
    const [selectedSkill, setSelectedSkill] = useState<ISkillsListViewDto | null>(null);
    const { isDestroying: isDestroyingSkill, onDestroy: onDestroySkill } = useDeleteSkill(refresh)
    const { isDestroying: isDestroyingSkillConnection, onDestroy: onDestroySkillConnect } = useDeleteSkillPositionConnect(refresh)
    const [expanded, setExpanded] = useTableStorageBackedState<ExpandedState>("skillListExpandedState", {})
    const [columnVisibility, setColumnVisibility] = useTableStorageBackedState<VisibilityState>('skillListVisibilityState', {});
    const [pagination, setPagination] = useTableStorageBackedState<PaginationState>('skillListPaginationState', { pageSize: 10, pageIndex: 0 });
    const [columnFilters, setColumnFilters] = useTableStorageBackedState<ColumnFiltersState>('skillListFilteringState', []);
    const [sorting, setSorting] = useTableStorageBackedState<SortingState>('skillListSortingState', []);

    const handleShowModal = useCallback((data: ISkillsListViewDto | null, modal: SkillModalType) => {
        setModalState({ ...modalState, [modal]: true })
        setSelectedSkill(data)
    }, [modalState])

    const onCancel = (modal: SkillModalType) => {
        setModalState({ ...modalState, [modal]: false })
        setSelectedSkill(null)
    }

    const columnHelper = createColumnHelper<ISkillsListViewDto>()
    const deviationTypeColumns = useMemo(
        () => [
            columnHelper.display({
                id: "expandable",
                size: 35,
                maxSize: 35,
                enableHiding: false,
                cell: ({ row }) =>
                    <>
                        {row.getCanExpand() && (
                            <Button
                                icon={<SemanticFeatherIcon FeatherIcon={row.getIsExpanded() ? ChevronDown : ChevronRight} />}
                                type="button"
                                onClick={row.getToggleExpandedHandler()}
                            />
                        )}
                    </>
            }),
            columnHelper.accessor("name", {
                id: "name",
                header: t('name') as any,
                size: 1000,
                cell: ({ row, getValue }) => (
                    <div style={{ paddingLeft: `${row.depth * 4}rem`, }}>
                        {row.getCanExpand() ? (
                            <>
                                {getValue()} ({row.subRows.length})
                            </>
                        ) : <span style={{ display: "flex" }}>{row?.depth !== 0 && <SemanticFeatherIcon FeatherIcon={User} />} {getValue()}</span>}

                    </div>
                ),
                footer: (props) => {
                    const filtered = props.table.getFilteredRowModel().rows;

                    if (filtered.length === 0) {
                        return null;
                    }

                    const totalCount = countRowsWithSubRows(filtered);

                    return (
                        <FooterLabel>{t('totalValue', { value: totalCount })}</FooterLabel>
                    );
                }
            }),
            columnHelper.accessor((r) => r.positions && !!r.positions.length, {
                id: "requiredSkills",
                header: t('requiredSkills') as any,
                meta: {
                    checkbox: true
                },
                cell: ({ row }) => {
                    if (row.original.positions) {
                        return <Checkbox checked={!!row.original.positions.length} />
                    } else {
                        return null
                    }
                }
            }),
            columnHelper.display({
                id: "actions",
                size: 140,
                enableHiding: false,
                cell: ({ row }) => {
                    const isDisabled = isDestroyingSkill || isDestroyingSkillConnection

                    return (
                        <>
                            {!row.depth ?
                                <>
                                    <Button
                                        title={t('connectPosition')}
                                        onClick={() => handleShowModal(row.original, "connect")}
                                        disabled={isDisabled}
                                        type="button"
                                        primary
                                        icon={<SemanticFeatherIcon FeatherIcon={GitBranch} />} />

                                    <Button title={t('edit')} type='button' onClick={() => handleShowModal(row.original, "edit")} disabled={isDisabled} icon={<SemanticFeatherIcon FeatherIcon={Edit} />} />
                                    <Button
                                        title={t('delete')}
                                        onClick={() => onDestroySkill({
                                            confirmText: `${t('confirm.deleteValue', { value: t('skill').toLowerCase() })} ${t('confirm.deleteSkillInfo')}`,
                                            id: row.original.id,
                                        })}
                                        type='button'
                                        color='red'
                                        disabled={isDisabled}
                                        icon={<SemanticFeatherIcon FeatherIcon={Trash2} color='white' />} />
                                </>
                                :
                                <Button
                                    onClick={() => onDestroySkillConnect({
                                        confirmText: t('confirm.deleteValue', { value: t('connectPosition').toLowerCase() }),
                                        skilldId: row.getParentRow()?.original.id ?? -1,
                                        positionId: row.original.id,

                                    })}
                                    disabled={isDisabled}
                                    type='button'
                                    title={t('deleteConnectedPosition')}
                                    color='red'
                                    icon={<SemanticFeatherIcon FeatherIcon={Trash2} color='white' />} />
                            }
                        </>
                    )
                }
            }),
        ], [columnHelper, t, handleShowModal, isDestroyingSkill, isDestroyingSkillConnection, onDestroySkill, onDestroySkillConnect])

    const table = useReactTable({
        data: data || [],
        columnResizeMode: "onChange",
        columns: deviationTypeColumns,
        state: {
            columnVisibility,
            expanded,
            pagination,
            columnFilters,
            sorting
        },
        enableGlobalFilter: false,
        enableGrouping: false,
        onExpandedChange: setExpanded,
        getSubRows: row => row.positions,
        onSortingChange: setSorting,
        onColumnVisibilityChange: setColumnVisibility,
        onPaginationChange: setPagination,
        onColumnFiltersChange: setColumnFilters,
        getExpandedRowModel: getExpandedRowModel(),
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
    })

    if (isLoading || !data) {
        return <Loading />
    }

    return (
        <FullHeightStackingContainer>
            <Message info>
                <Message.Header>
                    {t('skillsAdmin.skillHeader')}
                </Message.Header>
                {t('skillsAdmin.skillMessageBody')}
            </Message>

            <TableReact table={table}
                onAddCallback={() => handleShowModal(null, "new")}
                canExport
                hideGroupRowValue={false}
                noRightBorder />

            <ModalWrapper title={t('addValue', { value: t('skill').toLowerCase() })} open={modalState.new} onClose={() => onCancel("new")} >
                <SkillForm onCancel={() => onCancel("new")} refresh={refresh} />
            </ModalWrapper>


            {selectedSkill &&
                <ModalWrapper title={t('editValue', { value: t('skill').toLowerCase() })} open={modalState.edit} onClose={() => onCancel("edit")} >
                    <SkillForm onCancel={() => onCancel("edit")} refresh={refresh} skill={selectedSkill} />
                </ModalWrapper>}

            {selectedSkill &&
                <ModalWrapper title={t('connectPosition')} open={modalState.connect} onClose={() => onCancel("connect")} >
                    <ConnectPositionForm skill={selectedSkill} onCancel={() => onCancel("connect")} refresh={refresh} />
                </ModalWrapper>}
        </FullHeightStackingContainer>
    )
}

export default SkillList
